.MainMenu {
  text-align: center;
}

.MainMenu-content {
  background-color: #f3f6f6;
  color: #282c34;

  height: 90vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
}

.MainMenu-header {
  background-color: #1d49a0;

  height: 10vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
}

.MainMenu-header img {
  width: 8vh;
  height: 8vh;
  margin-left: 20px;
}

.MainMenu-header h1 {
  width: 300px;
  color: #f3f6f6;
  font-size: calc(20px + 3vmin);
  margin-right: calc(50vw - 150px);
}

.MainMenu-sub-header h2 {
  color: #1d49a0;
  font-size: calc(20px + 1vmin);
  padding-left: 10vw;
  padding-right: 10vw;
  margin: 0.5em;
}

.MainMenu-segment {
  height: 10vh !important;
  width: max(340px, 60vw) !important;
}

.MainMenu-segment label {
  font-size: 1.1em;
}

.MainMenu-button {
  width: 30vh !important;
  height: 15vh !important;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 3em !important;

  margin-bottom: 10vh !important;
}