.VoiceApp {
  text-align: center;
}

.VoiceApp-topbar {
  display: flex;
  width: 100vw;
  justify-content: space-between;
}

.VoiceApp-exit-button {
  width: 10vh !important;
  height: 5vh !important;
  margin: 20px !important;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1em !important;

  margin-bottom: 10vh !important;
}

.VoiceApp-listening-sign {
  padding: 0;
  color:red;
}

.VoiceApp-listening-sign.warning {
  font-size: 20px;
  width: 500px;
  margin: 20px;
  margin-right: calc(50vw - 250px);
}


@media only screen 
and (min-device-width : 300px) 
and (max-device-width : 667px) 
and (orientation : portrait) 
and (-webkit-min-device-pixel-ratio : 2) { 
  .VoiceApp-listening-sign.warning {
    font-size: 15px;
    width: 60vw;
    margin-right: 30vw;

  }
}


.VoiceApp-listening-sign.flashing {
  font-size: 20px;
  margin: 20px;
  margin-right: calc(50vw - 50px);

  -webkit-animation: flickerAnimation 4s infinite;
  -moz-animation: flickerAnimation 4s infinite;
  -o-animation: flickerAnimation 4s infinite;
   animation: flickerAnimation 4s infinite;
}

.VoiceApp-content {
  background-color: #282c34;
  color: white;

  height: 95vh;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.VoiceApp-header {
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 6vmin);
}

.VoiceApp-header h1 {
  margin-top: 0;
}

.VoiceApp-transcript {
  background-color: #ebf0fc;

  height: 5vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}

.VoiceApp-remaining {
  margin: 20px;
  padding: 0;
  font-size: 20px;
  margin-top: auto
}

.VoiceApp-action-button {
  width: 30vh !important;
  height: 10vh !important;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1.8em !important;

  margin-bottom: 10vh !important;
}