.ResultsPage {
  text-align: center;
}

.ResultsPage-content {
  background-color: #f3f6f6;
  color: #282c34;

  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
}

.ResultsPage-header h1 {
  color: #1d49a0;
  font-size: calc(20px + 6vmin);
  margin-bottom: 0.5em;
}

.ResultsPage-results-container {
  width: 50vw;
}

.ResultsPage-results-table {
  height: 30vh;
}

.ResultsPage-results-table span {
  font-size: 1em;
}

.ResultsPage-button {
  width: 30vh !important;
  height: 15vh !important;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 3em !important;

  margin-bottom: 10vh !important;
}